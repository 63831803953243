<template>
  <pop-up-main
    v-on:closepopup="$emit('closepopup')"
    title="经处理的歌"
  >
    <div class="content">
    </div>
  </pop-up-main>
</template>

<script>
import PopUpMain from './Main.vue'

export default {
  name: 'PopUpExplainTreated',
  components: {
    PopUpMain
  }
}
</script>

<style scoped>
</style>
