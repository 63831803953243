<template>
  <pop-up-main
    v-on:closepopup="$emit('closepopup')"
    title="歌曲详情"
  >
    <div class="content">
      <p><span>歌名：</span>{{song.name}}</p>
      <p><span>演唱者：</span>{{song.artist}}</p>
      <p><span>演唱状态：</span>{{song.status}}</p>
      <p v-if="song.orginal_artist!==''"><span>原唱：</span>{{song.orginal_artist}}</p>
      <p><span>留言：</span>{{song.note}}</p>
        <span>时间点：</span>{{song.record.timecode}}
    </div>
  </pop-up-main>
</template>

<script>
import PopUpMain from './Main.vue'

export default {
  name: 'PopUpDetails',
  components: {
    PopUpMain
  },
  data() {
    return {
    }
  },
  props: [
    'song'
  ]
}
</script>

<style scoped>
.content >p >span {
  font-weight: bold;
}
</style>
