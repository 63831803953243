<template>
  <div class="c-footer">
    <p>
      <a v-on:click="show_credit=true" class="credit">关于本站</a>
    </p>
    <pop-up-credit
      v-if="show_credit"
      v-on:closepopup="show_credit=false"
    />
  </div>
</template>

<script>
import PopUpCredit from './PopUp/Credit.vue'
export default {
  name: 'Footer',
  components: {
    PopUpCredit
  },
  data () {
    return {
      cutter: [],
      show_credit: false
    }
  },
  mounted () {
    let l = window.meumy.cutter_list
    this.cutter = l[Math.floor(Math.random()*l.length)]
  }
}
</script>

<style scoped>
.c-footer {
  color: gray;
  font-size: 0.9rem;
  margin-top: 3rem;
}
hr {
  border: none;
  border-top: 1px rgb(207, 207, 207) solid;
}
.links a {
  margin-right: 1rem;
}
.credit {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
</style>
