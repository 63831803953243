<template>
  <pop-up-main
    v-on:closepopup="$emit('closepopup')"
    title="欢迎！"
  >
    <div class="content">
      <p>nay</p>
      <p>快乐听歌鸭！~</p>
    </div>
  </pop-up-main>
</template>

<script>
import PopUpMain from './Main.vue'

export default {
  name: 'PopUpInfo',
  components: {
    PopUpMain
  },
  data() {
    return {
      cutter: window.meumy.cutter_list
    }
  }
}
</script>

<style scoped>
.content >p >a {
  margin-right: 1rem;
}
</style>
